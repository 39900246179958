import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
//
export function esqueceuSenha(valor) {
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().enviarEsqueceuSenha}/${valor.cpf.replace(/[^\d]+/g, '')}`)
      .then((resp) => {
        resolve(resp);
      })
      .catch(() => {
        store.commit('SET_ALERTA', {
          ativado: true,
          mensagem: 'Não foi possível enviar a requisição.',
          tipo: 'erro',
        });
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
