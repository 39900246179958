<template>
  <div id="esqueci-senha">
    <div class="login-container">
      <div class="login-container-logo">
        <img src="@/assets/img/icone/icone-bg-logo.png" alt="+TOP Dash" />
        <img src="@/assets/img/logos/topnews-v-n.svg" alt="+TOP Dash" />
      </div>
      <div class="login-container-form">
        <h1>Esqueceu<br />sua senha?</h1>
        <p>Tudo bem! vamos te ajudar!<br />comece digitando seu cpf!</p>
        <form @submit.prevent class="form">
          <p>CPF:</p>
          <input type="tel" v-mask="'999.999.999-99'" placeholder="CPF" v-model.trim="form.cpf" @blur="$v.form.cpf.$touch()" />
          <div class="form-esqueci-senha-btns">
            <button class="btn-enviar" :disabled="$v.form.$invalid" @click="enviarCpf">Próximo</button>
            <button class="link-voltar" @click="voltar">Voltar</button>
          </div>
        </form>
        <p><span>Dúvidas?</span> Ligue para <a href="tel:08007800606" target="_blank">0800 780 0606</a> e fale com uma de nossas atendentes</p>
      </div>
    </div>
  </div>
</template>

<script>
import AwesomeMask from 'awesome-mask';
import { esqueceuSenha } from '@/services/login/esqueceuSenha';
import { required } from 'vuelidate/lib/validators';
import { validaCpf } from '@/utils/vuelidate';
export default {
  name: 'EsqueciSenha',
  directives: {
    mask: AwesomeMask,
  },
  data() {
    return {
      form: {
        cpf: '',
      },
    };
  },
  validations: {
    form: {
      cpf: {
        required,
        cpf: validaCpf,
      },
    },
  },
  methods: {
    esqueceuSenha,
    enviarCpf() {
      if (this.$v.form.$invalid) return false;
      esqueceuSenha(this.form).then((resp) => {
        if (!resp.data.sucesso) {
          return this.$store.commit('SET_ALERTA', {
            ativado: true,
            mensagem: String(resp.data.mensagem),
            tipo: 'erro',
          });
        }
        const data = {
          celular: resp.data.dados.celular,
          cpf: resp.data.dados.cpf,
          cpfCompleto: this.form.cpf.replace(/[^\d]+/g, ''),
          email: resp.data.dados.email,
          nome: resp.data.dados.nome,
        };
        sessionStorage.setItem('redefinicao', JSON.stringify(data));
        this.$router.push('/redefinir-senha');
      });
    },
    voltar() {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
#esqueci-senha {
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 20px 20px 65px 20px;
  background: $roxo5;
  @media (max-width: 600px) {
    padding: 30px 20px 100px 20px;
  }
  .login-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    > div {
      width: 100%;
      max-width: 320px;
    }
  }
  .login-container-logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
    padding-top: 30px;
    @media (max-width: 1400px) {
      margin-bottom: 150px;
    }
    @media (max-width: 600px) {
      margin-bottom: 100px;
    }
    @keyframes rotacionar {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
    img:first-of-type {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: rotacionar 120s linear infinite;
      @media (max-width: 600px) {
        top: calc(50% - 20px);
      }
    }
    img:last-of-type {
      position: relative;
      max-width: 165px;
    }
  }
  .login-container-form {
    position: relative;
    h1 {
      font-size: 28px;
      font-weight: 900;
      text-transform: uppercase;
      color: $verde;
      @media (max-width: 600px) {
        text-align: center;
      }
    }
    > p {
      font-size: 14px;
      color: $branco;
      line-height: 18px;
      margin: 5px 0 15px 0;
      span {
        color: $verde-claro;
      }
      a {
        color: $branco;
      }
    }
    .form-esqueci-senha-btns {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      justify-content: space-between;
      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    .btn-enviar {
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 132px;
      height: 42px;
      cursor: pointer;
      transition: 0.3s;
      border: none;
      border-radius: 25px;
      outline: none;
      background: #bee805;
      &:hover {
        background: darken(#bee805, 5%);
      }
      &:disabled {
        cursor: default;
        background: $cinza1;
      }
      @media (max-width: 600px) {
        margin: 0 auto 20px auto;
      }
    }
    .link-voltar {
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline;
      text-transform: uppercase;
      color: $branco;
      border: none;
      outline: none;
      background: none;
    }
    @media (max-width: 600px) {
      .login-container-form-link {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
