<template>
  <div>
    <transition name="fade" mode="out-in">
      <RedefinicaoSenha v-if="modalAberto" @fecharModal="modalAberto = false" />
    </transition>
    <div id="redefinir-senha">
      <div class="redefinir-senha-container">
        <div class="redefinir-senha-container-logo">
          <img src="@/assets/img/icone/icone-bg-logo.png" alt="+TOP Dash" />
          <img src="@/assets/img/logos/topnews-v-n.svg" alt="+TOP Dash" />
          <p>Recuperar<br />senha</p>
        </div>
        <div class="redefinir-senha-container-form">
          <div class="redefinir-senha-regras">
            <p>A nova senha deve ter <span>8 Caracteres</span>,<br />sendo ao menos:</p>
            <div class="redefinir-senha-regras-container">
              <div class="redefinir-senha-regras-item" data-senha="maiuscula" :class="{ active: validaRegraMaiuscula }">
                <span>A</span>
                <div></div>
                <p>1 Letra Maiúscula</p>
              </div>
              <div class="redefinir-senha-regras-item" data-senha="minuscula" :class="{ active: validaRegraMinuscula }">
                <span>a</span>
                <div></div>
                <p>1 Letra Minúscula</p>
              </div>
              <div class="redefinir-senha-regras-item" data-senha="especial" :class="{ active: validaRegraEspecial }">
                <span>@</span>
                <div></div>
                <p>1 Caracter Especial</p>
              </div>
              <div class="redefinir-senha-regras-item" data-senha="numero" :class="{ active: validaRegraNumero }">
                <span>1</span>
                <div></div>
                <p>1 Número</p>
              </div>
              <div class="redefinir-senha-regras-item" data-senha="quantidade" :class="{ active: validaRegraQuantidade }">
                <span>8</span>
                <div></div>
                <p>8 Caracteres</p>
              </div>
            </div>
          </div>
          <div class="redefinir-senha-form">
            <form @submit.prevent class="form">
              <p>Senha:</p>
              <div class="redefinir-senha-container-input">
                <input ref="senha1" :class="{ erro: senhaExpiradaErro != '' }" type="password" placeholder="Nova Senha" autocomplete="nope" maxlength="100" v-model.trim="form.senha" @blur="$v.form.senha.$touch()" />
                <i class="icone-olho-senha dark" :class="{ active: senha1 }" @click="toggleMostrarSenha('senha1')"></i>
              </div>
              <p>Confirme a senha:</p>
              <div class="redefinir-senha-container-input">
                <input ref="senha2" :class="{ erro: confSenhaExpiradaErro != '' }" type="password" placeholder="Confirmar Nova Senha" maxlength="100" v-model.trim="form.confSenha" @blur="$v.form.confSenha.$touch()" />
                <i class="icone-olho-senha dark" :class="{ active: senha2 }" @click="toggleMostrarSenha('senha2')"></i>
              </div>
              <div class="form-esqueci-senha-btns">
                <button class="btn-redefinir-senha" :disabled="$v.form.$invalid" @click="abrirModal">Enviar</button>
                <button class="link-voltar" @click="voltar">Voltar</button>
              </div>
            </form>
            <p><span>Dúvidas?</span> Ligue para <a href="tel:08007800606" target="_blank">0800 780 0606</a> e fale com uma de nossas atendentes</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const RedefinicaoSenha = () => import('@/components/redefinicao-senha/RedefinicaoSenha.vue');
import { required, sameAs } from 'vuelidate/lib/validators';
import { senhaExpiradaErro, confSenhaExpiradaErro, validaSenha } from '@/utils/vuelidate';
export default {
  name: 'RedefinirSenha',
  components: {
    RedefinicaoSenha,
  },
  data() {
    return {
      senha1: false,
      senha2: false,
      form: {
        senha: '',
        confSenha: '',
      },
      modalAberto: false,
    };
  },
  computed: {
    senhaExpiradaErro,
    confSenhaExpiradaErro,
    modal() {
      return this.$store.state.modal;
    },
    validaRegraMaiuscula() {
      return /(?=.*[A-Z])/g.test(this.form.senha);
    },
    validaRegraMinuscula() {
      return /(?=.*[a-z])/g.test(this.form.senha);
    },
    validaRegraEspecial() {
      return /[^\w\s]/g.test(this.form.senha);
    },
    validaRegraNumero() {
      return /(?=.*[0-9])/g.test(this.form.senha);
    },
    validaRegraQuantidade() {
      return /^(?=.{8,}$)/g.test(this.form.senha);
    },
  },
  validations: {
    form: {
      senha: { required, senha: validaSenha },
      confSenha: { required, senha: validaSenha, matchText: sameAs('senha') },
    },
  },
  methods: {
    toggleMostrarSenha(ref) {
      if (this.$refs[ref].type == 'password') {
        this[ref] = true;
        this.$refs[ref].type = 'text';
      } else {
        this[ref] = false;
        this.$refs[ref].type = 'password';
      }
    },
    voltar() {
      this.$router.push('/esqueci-minha-senha');
    },
    abrirModal() {
      this.$store.commit('user/SET_SENHA_REDEFINICAO', this.form.confSenha);
      this.modalAberto = true;
    },
  },
  mounted() {
    if (this.modal != '') {
      this.$store.commit('SET_MODAL', '');
    }
  },
};
</script>

<style lang="scss">
#redefinir-senha {
  display: flex;
  overflow: hidden;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 65px 20px;
  background: $roxo5;
  @media (max-width: 900px) {
    padding: 35px 20px 100px 20px;
  }
  .redefinir-senha-container {
    display: flex;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    @media (max-width: 900px) {
      align-items: center;
      flex-direction: column;
    }
    > div {
      width: 50%;
      @media (max-width: 900px) {
        width: 100%;
        max-width: 320px;
      }
    }
  }
  .redefinir-senha-container-logo {
    position: relative;
    padding-top: 130px;
    padding-right: 110px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 900px) {
      max-width: 250px !important;
      padding-top: 20px;
      margin-bottom: 60px;
      padding-right: 0;
    }
    p {
      font-size: 28px;
      font-weight: 600;
      position: relative;
      color: $verde;
      text-transform: uppercase;
    }
    @keyframes rotacionar {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
    img:first-of-type {
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 120px);
      transform: translate(-50%, -50%);
      animation: rotacionar 120s linear infinite;
      @media (max-width: 900px) {
        left: calc(50% - 60px);
      }
    }
    img:last-of-type {
      position: relative;
      max-width: 100%;
    }
  }
  .redefinir-senha-container-form {
    position: relative;
    .redefinir-senha-regras {
      width: 100%;
      margin-bottom: 30px;
      > p {
        font-size: 18px;
        margin-bottom: 25px;
        text-align: center;
        color: $branco;
        span {
          font-weight: 900;
        }
        @media (max-width: 550px) {
          font-size: 16px;
        }
      }
      .redefinir-senha-regras-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        max-width: 370px;
        .redefinir-senha-regras-item {
          width: calc(100% / 5 - 10px);
          span {
            font-size: 34px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            transition: background 0.3s, color 0.3s;
            color: $texto-escuro;
            border-radius: 20px;
            background: $branco;
            &:before {
              display: block;
              padding-bottom: 100%;
              content: ' ';
            }
          }
          @media (max-width: 550px) {
            width: calc(100% / 5 - 5px);
            span {
              font-size: 8vw;
              border-radius: 12px;
            }
          }
          div {
            position: relative;
            left: 50%;
            width: 75%;
            height: 9px;
            margin: 10px 0;
            transition: background 0.3s;
            transform: translateX(-50%);
            border-radius: 10px;
            background: $branco;
            @media (max-width: 450px) {
              height: 6px;
              margin: 10px 0 5px 0;
            }
          }
          p {
            font-size: 10px;
            line-height: 1.2em;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 25px;
            transition: color 0.3s;
            text-align: center;
            color: $branco;
            @media (max-width: 450px) {
              font-size: 2.2vw;
              line-height: 1.2em;
            }
          }
          &.active {
            span {
              color: $texto-claro;
              background: rgba(255, 255, 255, 0.2);
            }
            div {
              background: $verde-claro;
            }
          }
        }
      }
    }
    .redefinir-senha-form {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      form {
        width: 100%;
        input {
          max-width: 100%;
        }
        .container-input {
          margin-bottom: 10px;
        }
        .form-esqueci-senha-btns {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          justify-content: space-between;
          @media (max-width: 600px) {
            flex-direction: column;
            justify-content: flex-start;
          }
        }
        .link-voltar {
          font-weight: 500;
          cursor: pointer;
          text-decoration: underline;
          text-transform: uppercase;
          color: $branco;
          border: none;
          outline: none;
          background: none;
        }
      }
      > p {
        font-size: 14px;
        color: $branco;
        line-height: 18px;
        margin: 5px 0 15px 0;
        span {
          color: $verde-claro;
        }
        a {
          color: $branco;
        }
      }
    }
  }
  .redefinir-senha-container-input {
    position: relative;
    .icone-olho-senha {
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      top: 15px;
      right: 15px;
      cursor: pointer;
      border: solid 1px $roxo5;
      border-radius: 75% 15%;
      transform: rotate(45deg);
      &:before {
        content: '';
        display: block;
        position: absolute;
        transform: rotate(-45deg);
        background: $roxo5;
        width: 5px;
        height: 5px;
        border: solid 1px $roxo5;
        border-radius: 50%;
        left: 3px;
        top: 3px;
        transition: 0.3s;
      }
      &:after {
        content: '';
        width: 1px;
        background: $roxo5;
        height: 18px;
        position: absolute;
        top: -2px;
        left: 6px;
        transform: rotate(-5deg);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
      }
      &.active {
        &:after {
          opacity: 1;
          visibility: visible;
        }
        &:before {
          opacity: 0.5;
        }
      }
    }
  }
  .btn-redefinir-senha {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 42px;
    cursor: pointer;
    transition: 0.3s;
    border: none;
    border-radius: 25px;
    outline: none;
    background: #bee805;
    &:hover {
      background: darken(#bee805, 5%);
    }
    &:disabled {
      cursor: default;
      background: $cinza1;
    }
    @media (max-width: 900px) {
      margin: 0 auto 20px auto;
    }
  }
}
</style>
